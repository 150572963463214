















import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'

import RegisterServiceUserForm from '@/partials/forms/Services/RegisterServiceUserForm.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'

@Component({
  components: {
    RegisterServiceUserForm
  }
})
export default class UsersToServiceCreate extends Mixins(FlashMessageMixin) {
  serviceRole: Role = {} as Role

  created () {
    const roleNames = {
      specialist: 'Especializando',
      visitor: 'Visitante',
      secretary: 'Secretaria Centro de Serviço',
      chief: 'Chefe de Serviço'
    }
    axios.get('roles/service/user')
      .then((response) => {
        this.serviceRole = response.data.data.find((serviceRole: Role) => serviceRole.name === roleNames[this.$route.params.person as ServicePerson])
      })
  }

  attachUserToService (user: Record<string, any>) {
    if (this.$route.params.person === 'chief') {
      const submitForm = {
        userId: user.user.id,
        confirm: user.confirm
      }
      axios.patch(`service/${this.$route.params.id}/chief`, snakeCaseKeys(submitForm))
        .then(() => {
          this.$router.push({ name: 'ServicesShow', params: { id: this.$route.params.id } })
        })
    } else {
      const submitForm = {
        users: [user.user.id],
        confirm: user.confirm
      }
      axios.post(`service/${this.$route.params.id}/users/role/${this.serviceRole.id}/sync`, submitForm)
        .then(() => {
          const flashMessage = {
            message: `O ${this.serviceRole.name} foi adicionado ao serviço com sucesso.`,
            isSuccess: true
          }

          this.$router.push({ name: 'ServicesShow', params: { id: this.$route.params.id } }, () => {
            this.setFlashMessage(flashMessage)
          })
        })
    }
  }
}
